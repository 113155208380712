import { Link, useLocation } from 'react-router-dom';
import { cn } from '../../utils/classMerge';
import './MobileMenu.scss';
import { useState } from 'react';
import { Drawer } from '@mui/material';
import NavBar from '../Sidebar/Sidebar';
import pages from '../../constants/pageNames';
import logo from '../../../assets/logo.svg';

interface Props {
	className?: string;
}

export const MobileMenu: React.FC<Props> = ({ className }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useLocation();

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<header className={cn(className, 'mobile-menu__wrapper')}>
			{/* Логотип */}
			<div className="mobile-menu__logo w-[50px]">
				<Link to="/">
					<img src={logo} alt="logo" />
				</Link>
			</div>
			{/* Название страницы */}
			<h2 className="mobile-menu__page">
				{pages[pathname as keyof typeof pages]}
			</h2>
			{/* Бургер */}
			<div>
				<button className="mobile-menu__burger" onClick={handleClick}>
					<span></span>
					<span></span>
					<span></span>
				</button>
			</div>
			<Drawer
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onClick={() => setIsOpen(false)}
			>
				<NavBar className="h-full rounded-none" />
			</Drawer>
		</header>
	);
};
