import React from 'react';
import './CustomInput.scss';
import { cn } from '../../utils/classMerge';

interface CustomInputProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
	label?: string;
	errorText?: string | null;
	size?: 'small' | 'medium' | 'large';
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
	({ label, errorText, size = 'medium', ...props }, ref) => {
		return (
			<div>
				{label && <label>{label}</label>}
				<input
					className={cn('input mt-2', {
						'h-8 md:h-9': size === 'small',
						'h-9 md:h-10': size === 'medium',
						'h-10 md:h-12': size === 'large',
					})}
					ref={ref}
					{...props}
				/>
				<div
					className={cn('flex text-red-500 mt-1', {
						'h-[14px]': size === 'small',
						'h-[17px]': size === 'medium',
						'h-[20px]': size === 'large',
					})}
				>
					{errorText && (
						<span
							className={cn({
								'text-xs': size === 'small',
								'text-sm': size === 'medium',
								'text-base': size === 'large',
							})}
						>
							{errorText}
						</span>
					)}
				</div>
			</div>
		);
	},
);

export default CustomInput;
