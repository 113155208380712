export function s2hms(s: number): string {
	const time = [
		Math.floor(s / 3600), // hours
		Math.floor(s / 60) % 60, // minutes
		Math.floor(s % 60), // seconds
	] as (number | string)[];

	if (Number(time[2]) < 10) {
		time[2] = '0' + time[2]; // Convert back to number
	}

	if (Number(time[1]) < 10) {
		time[1] = '0' + time[1];
	}

	return time.join(':');
}
