import { Outlet, useLocation } from 'react-router-dom';
import './Layout.scss';
import NavBar from '../../shared/components/Sidebar/Sidebar.tsx'; // Подключаем стили
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import PATH from '../../shared/constants/path.ts';
import { MobileMenu } from '../../shared/components/MobileMenu/MobileMenu.tsx';

const LayoutWrapper = () => {
	const location = useLocation();
	const isLoginPage = location.pathname === PATH.login; // Проверка на страницу логина

	return (
		<>
			{!isLoginPage && <MobileMenu />}
			<div className={!isLoginPage ? 'layout-container' : ''}>
				{!isLoginPage && (
					<aside>
						<NavBar />
					</aside>
				)}
				<main className={!isLoginPage ? 'layout' : ''}>
					<Outlet />
				</main>
			</div>
		</>
	);
};

export default LayoutWrapper;
