import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './sidebar.scss';
import { logout, removeTokens } from '../../api/api.config.ts';
import Cookies from 'js-cookie';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import PATH from '../../constants/path.ts';
import { cn } from '../../utils/classMerge.ts';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
}

const NavBar: React.FC<Props> = ({ className }) => {
	const navigate = useNavigate();
	const refreshToken = Cookies.get('refreshToken');

	const { pathname: path } = useLocation();

	const handleLogout = async () => {
		if (refreshToken) {
			await logout(refreshToken);
		}
		removeTokens();
		navigate(PATH.login);
	};

	return (
		<nav className={cn(className, 'aside-container')}>
			<ul>
				<li className="main-menu-list">
					<button
						className={`${path === PATH.admin && 'is-active'} menu-item`}
						onClick={() => navigate(PATH.admin)}
					>
						<SupervisedUserCircleIcon />
						<span>Пользователи</span>
					</button>
					<button
						className={`${path === PATH.articles && 'is-active'} menu-item`}
						onClick={() => navigate(PATH.articles)}
					>
						<LibraryBooksIcon />
						<span>Статьи</span>
					</button>
					<button
						className={`${path === PATH.appVersion && 'is-active'} menu-item`}
						onClick={() => navigate(PATH.appVersion)}
					>
						<AppSettingsAltIcon />
						<span>Версия приложения</span>
					</button>
					<button className="menu-item" onClick={handleLogout}>
						<ExitToAppIcon />
						<span>Выйти</span>
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
