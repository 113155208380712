import CustomInput from '../CustomInput/CustomInput';
import { SearchFilter } from '../../../widgets/UserList/api/fetchUsers.api';

import './UserSearch.scss';
import useDebounce from '../../utils/customHooks';

type SearchUsersProps = {
	filter: SearchFilter;
	setFilter: React.Dispatch<React.SetStateAction<SearchFilter>>;
};

const SearchUsers: React.FC<SearchUsersProps> = ({ filter, setFilter }) => {
	const debouncedSearchTerm = useDebounce(filter.searchTerm, 800);

	const errorText =
		debouncedSearchTerm && debouncedSearchTerm.length < 3
			? 'Не менее 3 символов'
			: '';

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilter({ ...filter, [e.target.name]: e.target.value });
	};

	return (
		<>
			<CustomInput
				label="Поиск пользователей"
				name="searchTerm"
				value={filter.searchTerm || ''}
				onChange={handleChange}
				size="large"
				errorText={errorText}
			/>
			<div className="filters__wrapper">
				<div className="filters__entity"></div>
			</div>
		</>
	);
};

export default SearchUsers;
