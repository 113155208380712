export const SuitcaseSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({
	width = 96,
	height = 96,
	stroke = '#fff',
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 96 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21 40.5h54M36 42v6a6 6 0 006 6h12a6 6 0 006-6v-6M48 27V6m-9 1.5h18M30 84h36a9 9 0 009-9V36a9 9 0 00-9-9H30a9 9 0 00-9 9v39a9 9 0 009 9z"
				stroke={stroke}
				strokeWidth={3}
			/>
			<path
				d="M33 84v3M63 84v3"
				stroke={stroke}
				strokeWidth={3}
				strokeLinecap="round"
			/>
		</svg>
	);
};
