import { api } from '../../../shared/api/api.config.ts';
import { User } from '../../../shared/types';

interface UserResponse {
	message: string;
	users: User[];
	lastPage: number;
	page: number;
	total: number;
}

export interface SearchFilter {
	searchTerm?: string;
	friendCode?: string;
}

export const fetchUsers = async (
	page: number = 1,
	limit: number = 10,
	{ searchTerm }: SearchFilter,
): Promise<UserResponse> => {
	const response = await api.get<UserResponse>(`/user/admin/list`, {
		params: {
			page,
			limit,
			searchTerm,
		},
	});
	return response.data;
};
