export const FastCountSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({
	width = 96,
	height = 96,
	fill = 'none',
	stroke = '#fff',
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 96 96"
			fill={fill}
			{...props}
		>
			<path
				d="M18.5 74h111M74 18.5v111M101.75 27.75l18.5 18.5M120.25 27.75l-18.5 18.5M37 24.667v24.666M24.667 37h24.666M98.667 101.75h24.667M98.667 120.25h24.667M24.667 111h24.666"
				stroke={stroke}
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
