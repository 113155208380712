import { useState } from 'react';
import { fetchUsers, SearchFilter } from './api/fetchUsers.api.ts';
import UserCard from '../../entities/UserCard/UserCard.tsx';
import './user-list.scss';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import PaginationComponent from '../../shared/components/PaginationComponent/PaginationComponent.tsx';
import useDebounce from '../../shared/utils/customHooks.ts';
import SearchUsers from '../../shared/components/UserSearch/UserSearch.tsx';

const UserList: React.FC = () => {
	const limit = 10; // Лимит пользователей на страницу

	const [page, setPage] = useState(1); // Текущая страница
	const [filter, setFilter] = useState<SearchFilter>({}); // Фильтр применяемый к запросу пользователей
	const debouncedFilter = useDebounce<SearchFilter>(filter, 500);

	const { data, isLoading } = useQuery({
		queryKey: ['users', page, debouncedFilter],
		queryFn: () => fetchUsers(page, limit, debouncedFilter),
		placeholderData: keepPreviousData,
		retry: 0,
		enabled:
			!debouncedFilter.searchTerm ||
			(debouncedFilter.searchTerm?.length ?? 0) > 2,
	});

	if (isLoading) return <ProgressSpinner />;

	return (
		<div className="user-list__wrapper">
			<div className="user-list__search">
				<SearchUsers filter={filter} setFilter={setFilter} />
			</div>

			{data?.users.map(user => <UserCard key={user.id} user={user} />)}

			{!data && <div className="m-4">Пользователей не найдено</div>}

			{/* Управление пагинацией */}
			{data && (
				<div className={'w-full flex justify-center items-center mt-4'}>
					<PaginationComponent setPage={setPage} data={data} />
				</div>
			)}
		</div>
	);
};

export default UserList;
