import { getImgUrl } from '../../shared/utils/getImgUrl.ts';
import { User } from '../../shared/types';
import './user-card.scss';
import { Link } from 'react-router-dom';
import PATH from '../../shared/constants/path.ts';

interface UserCardProps {
	user: User;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
	return (
		<div className="user-card">
			<div className="avatar-name">
				<Link to={`${PATH.users}/${user.id}`}>
					<img
						src={getImgUrl(user.avatar, user.gender)}
						alt="avatar"
						className="user-card__avatar"
					/>
				</Link>
				<div>
					<Link to={`${PATH.users}/${user.id}`} className="user-card__name">
						<strong>{user.name ?? 'Аноним'}</strong>
						<span>Новичок</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default UserCard;
