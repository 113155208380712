import { AssociationsPlusSvg } from '../../assets/icons/AssociationsPlusSvg';
import { AssociationsSvg } from '../../assets/icons/AssociationsSvg';
import { FastCountSvg } from '../../assets/icons/FastCountSvg';
import { SuitcaseSvg } from '../../assets/icons/SuitcaseSvg';

export const gameKeysOptions = [
	{
		value: 'fastCount',
		name: 'Быстрый счет',
		color: '--primary-color',
		icon: <FastCountSvg stroke="var(--primary-color)" />,
	},
	{
		value: 'associations',
		name: 'Ассоциации',
		color: '--green-accent-color',
		icon: <AssociationsSvg stroke="var(--green-accent-color)" />,
	},
	{
		value: 'suitcase',
		name: 'Чемодан',
		color: '--purple-accent-color',
		icon: <SuitcaseSvg stroke="var(--purple-accent-color)" />,
	},
	{
		value: 'associationsPlus',
		name: 'Ассоциации+',
		color: '--purple-accent-color',
		icon: <AssociationsPlusSvg stroke="var(--purple-accent-color)" />,
	},
];
