import './block-field.scss';
import { cn } from '../../utils/classMerge';
import { ProgressSpinner } from 'primereact/progressspinner';

interface BlockFieldProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
	title?: string;
	isLoading?: boolean;
}

const BlockField: React.FC<BlockFieldProps> = ({
																								 children,
																								 className,
																								 title,
																								 isLoading,
																								 ...props
																							 }) => {
	return (
		<div className="block-field" {...props}>
			{title && <div className="block-field__title">{title}</div>}

			<div className={cn('block-field__content', className)}>
				{isLoading && <ProgressSpinner style={{ height: 24 }} />}
				{children}
			</div>
		</div>
	);
};

export default BlockField;
