import { api } from '../../../shared/api/api.config';
import { User } from '../../../shared/types';

type GameStats = {
	correctAnswersCount?: number;
	countGames?: number;
	gameName: string;
	sumStars?: number;
	timeInGame?: number;
};

type UserResponse = {
	message: string;
	user: User;
	gameStats: GameStats[];
};

export const fetchUser = async (id: string): Promise<UserResponse> => {
	const response = await api.get<UserResponse>(`/user/stats/${id}`);
	return response.data;
};

type UserTodayTimeResponse = {
	totalTimeSpentToday: number;
	gameCount: number;
	message: string;
};

export const fetchUserTodayTime = async (
	userId: string,
): Promise<UserTodayTimeResponse> => {
	const response = await api.get<UserTodayTimeResponse>(`admin/today-time`, {
		params: {
			userId,
		},
	});
	return response.data;
};

type UserBestTimeResponse = {
	message: string;
	bestTimes: { gameKey: string; bestTime: number; totalStars: number }[];
};

export const fetchUserBestTime = async (
	userId: string,
): Promise<UserBestTimeResponse> => {
	const response = await api.get<UserBestTimeResponse>(`/admin/best-times`, {
		params: {
			userId,
		},
	});
	return response.data;
};
