export const AssociationsPlusSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({
	width = 96,
	height = 96,
	stroke = '#fff',
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 148 148"
			fill="none"
			{...props}
		>
			<path
				d="M87.875 71.688a13.875 13.875 0 10-27.75 0 13.875 13.875 0 0027.75 0zM39.313 94.813a13.875 13.875 0 10-27.75 0 13.875 13.875 0 0027.75 0zM39.313 48.563a13.875 13.875 0 11-27.75 0 13.875 13.875 0 0127.75 0zM136.438 94.813a13.875 13.875 0 10-27.75 0 13.875 13.875 0 0027.75 0zM136.438 48.563a13.875 13.875 0 11-27.75-.001 13.875 13.875 0 0127.75 0zM87.875 122.562a13.876 13.876 0 10-27.752.001 13.876 13.876 0 0027.752-.001zM87.875 25.438a13.875 13.875 0 11-27.75 0 13.875 13.875 0 0127.75 0zM74 85.563v20.812M74 57.813V41.624M39.313 55.5l23.124 6.938M39.313 87.875l23.124-6.938M108.688 55.5l-23.126 6.938M108.688 87.875l-23.126-6.938"
				stroke={stroke}
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
