export const AssociationsSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({
	width = 96,
	height = 96,
	stroke = '#fff',
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 96 96"
			fill="none"
			{...props}
		>
			<path
				d="M57 21a9 9 0 10-18 0 9 9 0 0018 0zM25.5 57a9 9 0 10-18 0 9 9 0 0018 0zM88.5 57a9 9 0 10-18 0 9 9 0 0018 0zM57 75a9 9 0 10-18 0 9 9 0 0018 0zM48 30v36M22.422 50.232l19.656-22.464M73.578 50.232L53.93 27.777"
				stroke={stroke}
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
