import PATH from './path';

const pages = {
	[PATH.dashboard]: 'Главная',
	[PATH.users]: 'Пользователи',
	[PATH.admin]: 'Пользователи',
	[PATH.articles]: 'Статьи',
	[PATH.appVersion]: 'Версия приложения',
} as const;

export default pages;
