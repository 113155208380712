enum PATH {
	dashboard = '/',
	users = '/users',
	articles = '/articles',
	admin = '/admin',
	appVersion = '/app-version',
	login = '/login',
}

export default PATH;
