import { useEffect } from 'react';
import {
	logout,
	refreshTokens,
	removeTokens,
} from '../../shared/api/api.config.ts';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import UserList from '../../widgets/UserList/UserList.tsx';
import PATH from '../../shared/constants/path.ts';

const AdminDashboard = () => {
	const accessToken = Cookies.get('accessToken');
	const refreshToken = Cookies.get('refreshToken');
	const navigate = useNavigate();

	useEffect(() => {
		if (!accessToken && refreshToken) {
			refreshTokens().catch(() => {
				logout(refreshToken);
				removeTokens();
				navigate(PATH.login);
			});
		} else if (!refreshToken) {
			removeTokens();
			navigate(PATH.login);
		}
	}, [accessToken, refreshToken, navigate]);

	return (
		<div>
			<UserList />
		</div>
	);
};

export default AdminDashboard;
