import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
	fetchUser,
	fetchUserBestTime,
	fetchUserTodayTime,
} from './api/userApi';
import { useParams } from 'react-router-dom';
import './user.scss';
import { getImgUrl } from '../../shared/utils/getImgUrl';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { s2hms } from '../../shared/utils/s2hms';
import { gameKeysOptions } from '../../shared/constants/gameKeysOptions';
import BlockField from '../../shared/components/BlockField/BlockField';
import { getGameIcon, getRusGameName } from '../../shared/utils/getGameOptions';

export const UserPage: React.FC = () => {
	const { id } = useParams();

	const { data: dataUsers, isLoading } = useQuery({
		queryKey: ['user', id],
		queryFn: () => fetchUser(id!),
		enabled: !!id,
	});
	const dataUserRows = [...(dataUsers?.gameStats || [])];

	const { data: dataTodayTime, isLoading: isLoadingTodayTime } = useQuery({
		queryKey: ['userTodayTime', id],
		queryFn: () => fetchUserTodayTime(id!),
		enabled: !!id,
	});

	const { data: dataBestTime, isLoading: isLoadingBestTime } = useQuery({
		queryKey: ['userBestTime', id],
		queryFn: () => fetchUserBestTime(id!),
		enabled: !!id,
		select: data => data.bestTimes,
	});

	if (isLoading)
		return (
			<div className="flex flex-col items-center">
				<ProgressSpinner />
			</div>
		);

	if (!dataUsers)
		return (
			<div className="flex flex-col items-center">
				<h2>Пользователь не найден</h2>
			</div>
		);

	return (
		<div className="user-wrapper">
			<div className="flex flex-col items-center">
				<img
					src={getImgUrl(dataUsers.user.avatar, dataUsers.user.gender)}
					alt="avatar"
					className="user__avatar"
				/>

				<BlockField className="user__name">
					<strong>{dataUsers.user.name ?? 'Аноним'}</strong>
					<span>Новичок</span>
				</BlockField>

				<BlockField className="user__info">
					<div className="user__info-item">Почта: {dataUsers.user.email}</div>
					<div className="user__info-item">
						Дата рождения: {dataUsers.user.birthdate?.toString().split('T')[0]}
					</div>
					<div className="user__info-item">
						Код реферала: {dataUsers.user.friendCode}
					</div>
					<div className="user__info-item">Пол: {dataUsers.user.gender}</div>
				</BlockField>

				<BlockField
					className="user__records"
					title="Рекорды"
					isLoading={isLoadingBestTime}
				>
					{dataBestTime?.map(row => (
						<div key={row.gameKey} className="user__records-row">
							<div className="user__records-row-icon">
								{getGameIcon(row.gameKey)}
							</div>
							<div>{getRusGameName(row.gameKey)}:&nbsp;</div>
							<div
								className="user__records-row-time"
								style={{
									color: `var(${
										gameKeysOptions.find(opt => opt.value === row.gameKey)
											?.color
									})`,
								}}
							>
								{s2hms(row.bestTime / 1000)}
							</div>
						</div>
					))}
				</BlockField>

				<BlockField
					className="user__todayActivity"
					title="Сегодняшняя активность"
					isLoading={isLoadingTodayTime}
				>
					<span>
						Всего времени:&nbsp;
						<span className="user__todayActivity-time">
							{s2hms(dataTodayTime?.totalTimeSpentToday ?? 0 / 1000)}
						</span>
						&nbsp; / Игр сыграно:&nbsp;
						<span className="user__todayActivity-time">
							{dataTodayTime?.gameCount}
						</span>
					</span>
				</BlockField>

				{/* Таблица с результатами игр */}
				{dataUserRows.length > 0 ? (
					<TableContainer component={Paper} sx={{ mt: 4, borderRadius: 3 }}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell>Игра</TableCell>
									<TableCell>Корректных ответов</TableCell>
									<TableCell>Количество игр</TableCell>
									<TableCell>Сумма звезд</TableCell>
									<TableCell>Время в игре</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dataUserRows.map(row => (
									<TableRow
										key={row.gameName}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
										}}
									>
										<TableCell component="th" scope="row">
											{getRusGameName(row.gameName)}
										</TableCell>
										<TableCell>{row.correctAnswersCount}</TableCell>
										<TableCell>{row.countGames}</TableCell>
										<TableCell>{row.sumStars}</TableCell>
										<TableCell>
											{row.timeInGame ? s2hms(row.timeInGame / 1000) : '0'}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<div className="m-4">Ни одной игры не сыграно</div>
				)}
			</div>
		</div>
	);
};
